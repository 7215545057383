import { action } from "helpers/actions";

const NAMESPACE = "administration/widgetbar";

export const ADD_OR_MOVE_WIDGET = NAMESPACE + "/ADD_WIDGET";
export const REMOVE_WIDGET = NAMESPACE + "/REMOVE_WIDGET";
export const CHANGE_WIDGET_VALUE = NAMESPACE + "/CHANGE_VALUE";
export const SELECT_WIDGET = NAMESPACE + "/SELECT_WIDGET";
export const FETCH_WIDGETBAR = NAMESPACE + "/FETCH_WIDGETBAR";
export const UPDATE_WIDGETBAR = NAMESPACE + "/UPDATE_WIDGETBAR";
export const SET_GROUP_ID = NAMESPACE + "/SET_GROUP_ID";
export const RESET_SUCCESSFUL_SAVE = NAMESPACE + "/RESET_SUCCESSFUL_SAVE";
export const RESET_ERRORS = NAMESPACE + "/RESET_ERRORS";
export const FETCH_WIDGETBARS = NAMESPACE + "/FETCH_WIDGETBARS";
export const FETCH_CALENDARS = NAMESPACE + "/FETCH_CALENDARS";
export const SET_IDENTIFIER = NAMESPACE + "/SET_IDENTIFIER";
export const FETCH_WIDGETBAR_ACTIONS = {
  REQUEST: `${FETCH_WIDGETBAR}/REQUEST`,
  SUCCESS: `${FETCH_WIDGETBAR}/SUCCESS`,
  FAILURE: `${FETCH_WIDGETBAR}/FAILURE`,
};
export const FETCH_CALENDARS_ACTIONS = {
  REQUEST: `${FETCH_CALENDARS}/REQUEST`,
  SUCCESS: `${FETCH_CALENDARS}/SUCCESS`,
  FAILURE: `${FETCH_CALENDARS}/FAILURE`,
};
export const UPDATE_WIDGETBAR_ACTIONS = {
  REQUEST: `${UPDATE_WIDGETBAR}/REQUEST`,
  SUCCESS: `${UPDATE_WIDGETBAR}/SUCCESS`,
  FAILURE: `${UPDATE_WIDGETBAR}/FAILURE`,
};

export const addOrMoveWidget = (data, index) =>
  action(ADD_OR_MOVE_WIDGET, { data, index });
export const removeWidget = () => action(REMOVE_WIDGET);
export const selectWidget = (data) => action(SELECT_WIDGET, { data });
export const setGroupId = (data) => action(SET_GROUP_ID, { data });
export const resetSuccessfulSave = (data) =>
  action(RESET_SUCCESSFUL_SAVE, { data });
export const changeValue = (propertyKey, data) =>
  action(CHANGE_WIDGET_VALUE, { propertyKey, data });
