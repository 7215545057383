import { createApiAction } from "helpers/api";
import { createRequestTypes, requestAction, action } from "./helpers";

export const FILE = {
  PUBLISH: "/FILE/PUBLISH",
  REMOVE: "/FILE/REMOVE",
  UPLOAD: createRequestTypes("/FILE/UPLOAD"),
};

export const FILES = {
  SET_UPLOAD_IDS: "/FILES/SET_UPLOAD_IDS",
  ADD_UPLOAD_ID: "FILES/ADD_UPLOAD_ID",
  UPLOAD: createRequestTypes("/FILES/UPLOAD"),
  PUBLISH: "/FILES/PUBLISH",
  DESTROY: "/FILES/DESTROY",
};

export const FOLDERS = {
  SELECT: "FOLDERS/SELECT",
};

export const FOLDER = {
  SELECT: "FOLDER/SELECT",
};

export const publishFiles = createApiAction({
  method: "POST",
  endpoint: ({ storageDirectory = "files" }) =>
    `/api/storage/${storageDirectory}/publish`,
  baseType: FILES.PUBLISH,
});

export const uploadFile = (payload) => {
  return requestAction(FILES.UPLOAD, payload);
};

export const destroyFile = createApiAction({
  method: "DELETE",
  endpoint: ({ storageDirectory, id }) =>
    `/api/storage/${storageDirectory}/${id}`,
  baseType: FILES.DESTROY,
});

export const selectFolder = ({ id, namespace }) =>
  action(FOLDER.SELECT, { meta: { id, namespace } });
