import { map } from "lodash";
import {
  REMOVE_WIDGET,
  CHANGE_WIDGET_VALUE,
  SELECT_WIDGET,
  ADD_OR_MOVE_WIDGET,
  SET_GROUP_ID,
  RESET_SUCCESSFUL_SAVE,
  SET_IDENTIFIER,
} from "actions/administration/widgetbar";
import {
  FETCH_CALENDARS_ACTIONS,
  FETCH_WIDGETBAR_ACTIONS,
  UPDATE_WIDGETBAR_ACTIONS,
} from "../../../actions/administration/widgetbar";

const initialState = {
  widgets: [],
  selectedWidgetIndex: 0,
  identifier: "",
  groupId: "",
  successfulSave: false,
  calendars: [],
};

const editWidgetbarReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_WIDGETBAR_ACTIONS.SUCCESS: {
      const widgets = map(action.payload.widgets, (w) => {
        // radio buttons are needing the bool as a string
        if (w.properties.show_age) {
          return {
            ...w,
            properties: {
              ...w.properties,
              show_age: w.properties.show_age.toString(),
            },
          };
        }
        return w;
      });

      return {
        ...state,
        widgets: widgets,
        selectedWidgetIndex: 0,
      };
    }
    case FETCH_CALENDARS_ACTIONS.SUCCESS: {
      return {
        ...state,
        calendars: action.payload.calendars,
      };
    }
    case UPDATE_WIDGETBAR_ACTIONS.SUCCESS: {
      return {
        ...state,
        successfulSave: true,
      };
    }
    case RESET_SUCCESSFUL_SAVE: {
      return {
        ...state,
        successfulSave: false,
      };
    }
    case ADD_OR_MOVE_WIDGET: {
      const widgets = [...state.widgets];
      const index = state.widgets.indexOf(action.data);
      let widget;

      // widget not found then it is new, if it is found then change position
      if (index === -1) {
        widget = {
          type: action.data.type,
          properties: {
            ...action.data.properties,
          },
        };
      } else {
        widget = widgets.splice(index, 1)[0];
      }
      widgets.splice(action.index, 0, widget);

      return {
        ...state,
        widgets: widgets,
        selectedWidgetIndex: action.index,
      };
    }
    case REMOVE_WIDGET: {
      return {
        ...state,
        widgets: [
          ...state.widgets.slice(0, state.selectedWidgetIndex),
          ...state.widgets.slice(
            state.selectedWidgetIndex + 1,
            state.widgets.length,
          ),
        ],
        selectedWidgetIndex: 0,
      };
    }
    case CHANGE_WIDGET_VALUE: {
      return {
        ...state,
        widgets: [
          ...state.widgets.slice(
            0,
            state.selectedWidgetIndex === 0 ? 0 : state.selectedWidgetIndex,
          ),
          {
            ...state.widgets[state.selectedWidgetIndex],
            properties: {
              ...state.widgets[state.selectedWidgetIndex].properties,
              [action.propertyKey]: action.data,
            },
          },
          ...state.widgets.slice(
            state.selectedWidgetIndex + 1,
            state.widgets.length,
          ),
        ],
      };
    }
    case SELECT_WIDGET: {
      return {
        ...state,
        selectedWidgetIndex: action.data,
      };
    }
    case SET_GROUP_ID: {
      return {
        ...state,
        ...action.data,
      };
    }
    case SET_IDENTIFIER: {
      return {
        ...state,
        identifier: action.data,
      };
    }
    default:
      return state;
  }
};

export default editWidgetbarReducer;
