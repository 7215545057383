import { createApiResultReducer } from "helpers/api";
import { combineReducers } from "redux";
import editWidgetbarReducer from "reducers/administration/widgetbar/edit";
import indexReducer from "reducers/administration/widgetbar/index";
import { FETCH_WIDGETBAR_ACTIONS } from "../../actions/administration/widgetbar";

export default combineReducers({
  fetchWidgetbar: createApiResultReducer(FETCH_WIDGETBAR_ACTIONS),
  edit: editWidgetbarReducer,
  index: indexReducer,
});
