import { combineReducers } from "redux";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { Group } from "../@types";

type GroupsBySlugState = {
  [slug: string]: Group | undefined;
};

const initialState: GroupsBySlugState = {};

const groupsBySlugSlice = createSlice({
  name: "groupsBySlug",
  initialState,
  reducers: {
    toggleFavorited(
      state,
      { payload }: PayloadAction<{ groupSlug: string; favorited: boolean }>,
    ) {
      const group = state[payload.groupSlug];
      if (group) group.favorited = payload.favorited;
    },
    toggleSubscribed(
      state,
      {
        payload,
      }: PayloadAction<{ groupSlug: string; subscription_status: boolean }>,
    ) {
      const group = state[payload.groupSlug];
      if (group) group.subscription_status = payload.subscription_status;
    },
  },
});

const reducer = combineReducers({
  bySlug: groupsBySlugSlice.reducer,
  myGroupSlugs: (state = null) => state,
});

export default reducer;
export const { toggleFavorited, toggleSubscribed } = groupsBySlugSlice.actions;
