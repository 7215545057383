import { FETCH_WIDGETBAR_ACTIONS } from "../../../actions/administration/widgetbar";

const initialState = {
  widgetbars: [],
};

const indexReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_WIDGETBAR_ACTIONS.SUCCESS: {
      return {
        ...state,
        widgetbars: action.payload.widgetbars,
      };
    }
    default: {
      return state;
    }
  }
};

export default indexReducer;
