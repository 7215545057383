import { combineReducers } from "redux";

import { FOLDER } from "actions/files";

const currentFolder = (state = null, action) => {
  switch (action.type) {
    case FOLDER.SELECT:
      return { id: action.meta.id, namespace: action.meta.namespace };
    default:
      return state;
  }
};

const reducer = combineReducers({
  currentFolder,
});

export default reducer;
